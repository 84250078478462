import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Link } from 'gatsby';

export default function Home() {
  return (
    <>
      <SEO siteTitle='Home' />
      <Layout>
        <div className='bg-yellow-300 text-center py-1 text-sm'>
          Your Trusted Appraisal Experts
        </div>
          <StaticImage
            src='../images/home-banner.png'
            placeholder='blurred'
            alt='Homepage Banner'
            className='w-full object-cover bg-cover h-full xl:h-96'
          />
        <main className='lg:p-32 md:py-40 p-8 grid gap-x-4 grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 justify-items-center'>
          <div className='flex flex-col items-center xl:items-start w-80 lg:w-96 mb-16 md:0'>
            <h1 className='text-gray-700 font-bold text-3xl mb-3'>Why Us?</h1>
            <p className='mb-3 text-center leading-7 xl:text-left'>
              Angel Valuation delivers top notch appraisals of commercial real
              estate. Clients know us for our independence, professionalism and
              technical competency.
            </p>
            <p className='mb-3 text-center leading-7 xl:text-left'>
              Whether you are an individual looking to make a key decision, a
              lender, institution, or a fiduciary, we are here to provide
              trusted value opinions.{' '}
            </p>
          </div>
          <div className='flex flex-col items-center xl:items-start w-80 lg:w-96 mb-16 md:0'>
            <h1 className='text-gray-700 font-bold text-3xl mb-3'>
              Privacy Statement
            </h1>
            <p className='mb-3 text-center leading-7 xl:text-left'>
              This website has no cookies and uses no tracking tools whatsoever.
              We will never cross-sell services to you, or to your institution.
              We promise to never sell your name for any purpose.{' '}
            </p>
            <p className='mb-3 text-center leading-7 xl:text-left'>
              At Angel Valuation, we are adamant that our clients have absolute
              confidentiality. Simply put, this is how we would want to be
              treated.
            </p>
          </div>
          <div className='flex flex-col items-center xl:items-start w-80 lg:w-96 mb-16 md:0'>
            <h1 className='text-gray-700 font-bold text-3xl mb-3'>
              Appraisal Process
            </h1>
            <p className='mb-3 text-center leading-7 xl:text-left'>
              If you are unfamiliar with the appraisal process, it all starts
              with property identification.
            </p>
            <p className='mb-3 text-center leading-7 xl:text-left'>
              Once the property is identified, we provide a flat fee for
              services. Most assignments are contracted with turnaround times
              between 8 to 28 days. The best way to expedite your order is to
              organise key information (i.e. rent rolls, leases, historical
              expenses, construction budgets).
            </p>
            <p className='mb-3 text-center leading-7 xl:text-left'>
              We invite you to
              <Link to='/contact' activeClassName='text-3xl'>
                <span className='underline hover:font-bold transition-all'> call or email us </span> 
              </Link>
              for a quote.
              You can count on us to respond within one hour.
            </p>
          </div>
        </main>
      </Layout>
    </>
  );
}
